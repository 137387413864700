@media (max-width: 1199.98px) {
  .layout-contacts__item_opacity {
    opacity: 1; }
  .we-register__title {
    text-align: center; }
  .we-register__phone {
    display: none; }
  .header__callback {
    width: 100%; }
  .top-callback {
    margin-top: 15px; }
    .top-callback__phone {
      display: none; }
  .we-register__wrap {
    justify-content: center;
    align-items: center; }
  .we-register__title {
    text-align: left; }
  .nav__link {
    padding-left: 10px;
    padding-right: 10px;
    text-align: center; }
  .banner__slogan {
    display: none; }
  .banner__title, .banner__subtitle {
    text-align: center; }
  .banner__input, .banner__btn {
    margin-left: auto;
    margin-right: auto; }
  .banner__confidencial {
    text-align: center; }
  .screen__anim-mouse {
    bottom: 0; }
  .service__nav-link {
    font-size: 23px; }
    .service__nav-link.active:after {
      content: none; }
  .slider-arrow_prev {
    left: 0; }
  .slider-arrow_next {
    right: 0; }
  .steps__item {
    width: 33.3%;
    margin-bottom: 30px; }
  .trust__item {
    width: 30%;
    margin-bottom: 25px; }
  .question__input {
    margin-bottom: 20px; }
  .team__item:not(:last-child) {
    margin-right: auto; }
  .team__item {
    margin-right: 25px; }
  .trust__item {
    margin: 0 auto; }
  .conclusion__item {
    max-width: 33%;
    flex: 0 0 33%; }
  .pagetitle__wrapper {
    flex-wrap: wrap; }
  .breadcrumbs__wrapper {
    flex-wrap: wrap; }
  .sertificate__item img {
    height: auto; } }

@media (max-width: 991.98px) {
  .header__callback {
    margin-top: 20px;
    width: auto; }
  .layout-menu {
    max-width: 100%;
    display: none; }
  .we-register {
    max-width: 40%;
    margin-top: 20px; }
    .we-register__wrap {
      display: flex; }
    .we-register__icon {
      width: 20%;
      margin-right: 15px; }
  .nav {
    max-width: 100%; }
    .nav.menu-open {
      display: block;
      position: absolute;
      top: 0;
      height: 100vh;
      align-items: flex-start;
      z-index: 3;
      overflow: hidden;
      transition: .5s all ease-in-out; }
    .nav__wrapper {
      flex-wrap: wrap; }
    .nav__item {
      width: 100%; }
    .nav__link {
      text-align: left; }
  .hamburger {
    display: block;
    position: absolute;
    top: 0px;
    right: 10px;
    width: 50px;
    height: 50px;
    transition: .5s all ease-in-out;
    z-index: 10; }
    .hamburger:before, .hamburger:after {
      position: absolute;
      content: '';
      left: 50%;
      transform: translateX(-50%);
      background-color: #5E6398;
      width: 30px;
      height: 3px;
      transition: .5s all ease-in-out; }
    .hamburger:before {
      top: 20px; }
    .hamburger:after {
      bottom: 20px; }
    .hamburger.is-active:before {
      transform: translateX(-50%) rotate(45deg);
      background-color: #fff;
      top: 24px; }
    .hamburger.is-active:after {
      transform: translateX(-50%) rotate(-45deg);
      background-color: #fff;
      bottom: 23px; }
  .results .row {
    justify-content: space-around; }
  .f-nav__wrapper {
    margin-bottom: 25px; }
  .question__input {
    width: 70%; }
  .team__item {
    margin-bottom: 20px;
    max-width: 45%; }
  .conclusion__item {
    max-width: 50%;
    flex: 0 0 50%; } }

@media (max-width: 767.98px) {
  .logo {
    margin: 0 auto 20px;
    justify-content: center;
    width: 100%; }
  .header__contacts {
    margin: 0 auto 20px;
    padding: 0 15px;
    max-width: 90%; }
  .we-register {
    max-width: 100%;
    margin-left: 30px; }
  .header__callback {
    margin: 20px auto 0; }
  .layout-contacts__item {
    display: block;
    margin-bottom: 10px; }
  .layout-contacts__adress {
    background-position: 0 3%; }
  .steps__item {
    width: 100%; }
  .question__input, .question__btn {
    margin-left: auto;
    margin-right: auto; }
  .trust__item {
    width: 90%;
    margin-left: auto;
    margin-right: auto; }
  .map__form {
    margin-bottom: 20px; }
  .d-wrap {
    flex-wrap: wrap; }
  .nav-tabs .nav-link {
    text-align: center; }
  .team__item {
    max-width: 90%;
    margin: 15px auto; }
  .contracts__item {
    text-align: center;
    margin-bottom: 10px; }
  .equipment-list__wrapper {
    flex-wrap: wrap; }
  .conclusion__item {
    max-width: 100%;
    flex: 0 0 100%; } }
